const deposit = [
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/deposits/Deposit.vue'),
    meta: {
      layout: 'content',
      resource: 'deposit',
      action: 'view',
    },
  },
]

export default deposit
