const account = [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/account/Account.vue'),
    meta: {
      layout: 'content',
      resource: 'account',
      action: 'view',
    },
  },
]

export default account
