import { mdiPiggyBank } from '@mdi/js'

export default [
  {
    title: 'Deposit Funds',
    icon: mdiPiggyBank,
    to: 'deposit',
    resource: 'account',
    action: 'view',
  },
]
